


<template>
  <div>


     <div class="flex flex-col">

  </div>

     <div class="va-table-responsive">
    <table class="va-table va-table--clickable">
      <thead>
      <tr>
        <th>Drink</th>
      </tr>
      </thead>
      <tbody>
        <tr>
            <td>
                <ul>
          
                  <li v-for="(item) in menuAll.Drinks" :key="item">
                    <div class="va-checkbox__input-container" tabindex="-1"><div class="va-checkbox__square">
                          <label  class="mb-6" v-bind:for="item">
                              <input  class="va-checkbox_custom" type="checkbox" v-model="drinkSelection.drinks" v-bind:value="item" ><label>{{ item.name }}</label>
                          </label>
                      </div>
                    </div>
                  </li>
                </ul>
            </td>
        </tr>
      </tbody>
    </table>

     <table class="va-table va-table--clickable">
      <thead>
      <tr>
        <th>Modifiers</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="modifier in menuAll.modifiers" :key="modifier" >
            <td>
                <ul>
                   <li>   <va-checkbox  v-model="drinkSelection.modifiers" :array-value="modifier"  :label="modifier" /> </li>
                </ul>
            </td>
        </tr>
      </tbody>
    </table>

<hr>

<pre style="font-size:10px; display:none;">COMPUTED:<br>
  {{computedMenu}}
</pre>

 <va-button  @click="showHide">Toggle dynamoDB </va-button>
<pre v-show="show"  style="font-size:10px; color:red">COMPUTED:<br>
  {{clone}}
</pre>



  </div>
  </div>
</template>

<script>
/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'

//const AWSsdk = require('aws-sdk')
//import { Auth } from 'aws-amplify'
//import axios from 'axios'
import menuAll from '../lib/menuSimple.json'
//import eventMenu from '../lib/menucurrentSimple.json'
import evm from '../lib/menuExample.json'
//import axios from 'axios'
//import Auth from '@aws-amplify/auth'

export default {
  name: 'App',
  props: ['config'],
  components: {

  },
  data () {
    return {

      menuAll: menuAll,
      evm:evm,
      showCustomContent: false,
      drinkSelection:{},
      clone:[],
      show:false

      
    }
  },

  async created () {
        //transpose DynamoDB event Menu into somthing the front-end can understand
        
     // if (this.drinkSelection==={}){
      let dynamoDB = this.config
      let MenuCurrentSimple={"drinks":[],"modifiers":[]}
       for (let i = 0; i < dynamoDB.L.length; i++) {
         MenuCurrentSimple.drinks.push({"name":dynamoDB.L[i].M.drink.S,"icon":dynamoDB.L[i].M.icon.S,"modifiers":[]})
          let modifiers = dynamoDB.L[i].M.modifiers.L
          let mName=''
            for (let a = 0; a < modifiers.length; a++) {
                mName = modifiers[a].M.Name.S
                MenuCurrentSimple.drinks[i].modifiers.push(mName)
                modifiers[a].M.Options.L.map(function (item){
                  if (!MenuCurrentSimple.modifiers.includes(mName+':'+item.S)){
                     MenuCurrentSimple.modifiers.push(mName+':'+item.S)
                  }
             })
            }
       }
       this.drinkSelection= MenuCurrentSimple
      //}
   
  },
  computed: {
    
    computedMenu: function () {
        let final=[]
        let clone = JSON.parse(JSON.stringify(this.drinkSelection))
        final.push(clone) 
        this.transposeMenu()
        return final
    }
  },
  methods: {
    //transpose front end checkbox results into dynamoDB language
    async transposeMenu(){
        let cloneDrinkSelection={}
            cloneDrinkSelection.Drinks={"L":[]}
        let clone = JSON.parse(JSON.stringify(this.drinkSelection))

        console.log('clone',clone)


        for (let i = 0; i < clone.drinks.length; i++) {
            let mods=[]
            for (let x = 0; x < clone.drinks[i].modifiers.length; x++) { 
              let modi = clone.drinks[i].modifiers[x]
              let values= []
              for (let a = 0; a < clone.modifiers.length; a++) { 
                let splitter = clone.modifiers[a].split(':')
                if(splitter[0]==modi){
                  values.push({
                        "S": splitter[1]
                    })
                }
              }
               mods.push({
                        "M": {
                        "Name": {
                            "S": modi
                        },
                        "Options": {
                            "L": values

                        }
                        }
                  })
              }
             cloneDrinkSelection.Drinks.L.push({
              "M": {
                    "available": {
                        "BOOL": true
                    },
                    "drink": {
                        "S": clone.drinks[i].name
                    },
                    "icon": {
                        "S": clone.drinks[i].icon
                    },
                    "modifiers": {
                        "L":  mods
                    }
                    }
                })
        }
         this.clone =cloneDrinkSelection
         this.$emit('updatedMenu',cloneDrinkSelection)
    },

    showHide(){
    return this.show = !this.show;
    }


  },
  async mounted () {
      


    


  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.va-checkbox_custom{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
  margin:10px;
  margin-right:15px;
}



</style>

