/*! Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *  SPDX-License-Identifier: MIT-0
 */

'use strict'

import { createApp } from 'vue'
import App from './App.vue'

// Vuestic theming
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'

// Global event bus
import mitt from 'mitt'
const emitter = mitt()

// Amplify imports
import Amplify from 'aws-amplify'

/* ===================================================
                      CONFIGURATION
    You must add your own values here! See the GitHub
    repo for more information. @jbesw
   =================================================== */

   Amplify.configure({
    Auth: {
      region: 'us-west-2',
      identityPoolRegion: 'us-west-2',
      userPoolId: 'us-west-2_GJKqhZqah',
      userPoolWebClientId: '61467higaemf6rlmjnp1qn0ds8',
      mandatorySignIn: false,
      authenticationFlowType: 'CUSTOM_AUTH',
    }
  })

const app = createApp(App)
app.use(VuesticPlugin)

app.config.globalProperties.$appName = 'Barista'

// ** Backend config **
app.config.globalProperties.$region = 'us-west-2'
app.config.globalProperties.$APIurl = 'https://h3mc2i0kq3.execute-api.us-west-2.amazonaws.com/Prod/'
app.config.globalProperties.$APIconfigURL = 'https://k2d3m0h01b.execute-api.us-west-2.amazonaws.com/Prod'
app.config.globalProperties.$ConfigEndpoint = 'https://k2d3m0h01b.execute-api.us-west-2.amazonaws.com/Prod/config'
app.config.globalProperties.$orderManagerEndpoint = 'https://h3mc2i0kq3.execute-api.us-west-2.amazonaws.com/Prod/'

// ** Websocket connection **
//  PoolId: Retrieve this with the CLI command: aws cognito-identity list-identity-pools --max-results 10 --region <<REGION>>
// ** Websocket connection **
app.config.globalProperties.$poolId = 'us-west-2:c52a11ab-4f20-40ec-920d-c102e2746c74' // 'YourCognitoIdentityPoolId'
//  IoTendpoint: Retrieve this with the CLI command: aws iot describe-endpoint --endpoint-type iot:Data-ATS --region us-west-2
app.config.globalProperties.$host = 'anput1xffmgcz-ats.iot.us-west-2.amazonaws.com' // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'

app.config.globalProperties.emitter = emitter
app.config.globalProperties.$adminApp = true
app.mount('#app')